<template>
    <div class="potential">
        <van-nav-bar
                title="查看详情"
                left-arrow
                @click-left="leftReturn"
                class="nav_bar_title"
                fixed
                placeholder
                style="margin-bottom: -10px"
        />
        <van-tabs v-model="active"
                  line-width="20"
                  line-height="5"
                  title-active-color="rgb(255,81,45)"
                  sticky
                  offset-top="1.2195121951219512rem"
        >
            <van-tab title="基础信息">
             <div>
                <div class="li" style="margin-top: 15px"><span>配货申请</span></div>
                <div v-for="(item,index) in applyList" :key="index" style="width: 100%;overflow: hidden" >
                    <div class="apply">
                        <div style="float: left;margin-left: 15px;margin-right: 15px;margin-top: 0px"><p>{{item.roomDetailedAddress}}</p></div>
                        <!--<div style="font-size: 15px;margin-left: 10px;"><p style="margin-bottom: -20px"><span style="line-height: 25px">{{apply.title}}</span></p></div>-->
                    </div>

                    <div class="panel" style="border-top-right-radius: 0px;border-top-left-radius: 0px;margin-top: 2px" >
                        <div class="panelHeader">
                            <div class="panelInfo two"><label>申请人：<span style="color: #878787">{{item.applicationGlobaluserName}}</span></label></div>
                            <div class="panelInfo" style="margin-bottom: 5px"><label>联系方式：<span style="color: #878787">{{item.applicationGlobaluserMoblile}}</span></label>
                            </div>
                        </div>
                        <div>
<!--                            <div class="panelInfo one" style="margin-bottom: 5px"><label>订单来源：<span style="color: #878787">{{item.costBear}}</span></label></div>-->
                            <div class="panelInfo" style="margin-bottom: 5px"><label>维修程度：<span style="color: #EE7E67">{{item.distributionStatus}}</span></label>
                            </div>
                        </div>
                        <div class="info float" style="font-size: 0.37333rem;margin-bottom: 0px"><p>预约时间：<span style="color: #EE7E67">{{item.appointmentTime.substring(0,10)}}</span></p></div>
                        <div class="info last" style="font-size: 0.37333rem;"><p>备注：<span style="color: #878787;line-height: 25px">{{item.afterRentDesprition?item.afterRentDesprition:'暂无'}}</span></p></div>
                    </div>
                </div>
             </div>

             <div v-if="afterRentStatus!=0&&afterRentStatus!=1&&afterRentStatus!==9">
                <div class="li" style="margin-bottom: -10px"><span>配货派单</span></div>
                <div class="panel" v-for="(itemss,index) in applyMessage" >
                    <div class="send"><p>配货人:<span class="span">{{itemss.userName}}</span></p></div>
                    <div class="send"><p>承担方:<span class="span">{{itemss.costBear}}</span></p></div>
                    <div class="send"><p>物品总计:<span class="span">{{itemss.totalFee?itemss.totalFee:'0.00'}}</span></p></div>
                    <div class="send"><p>配货上门时间:<span class="span" style="color: #EE7E67">{{itemss.appointmentTime}}</span></p></div>
                    <div class="send" style="margin-bottom: 15px"><p>配货描述:<span class="span">{{itemss.distributionDescription?itemss.distributionDescription:'暂无'}}</span></p></div>
                </div>
             </div>


             <div v-if="afterRentStatus==4 || afterRentStatus==11">
                <div class="li" style="margin-bottom: -10px"><span>配货完成</span></div>
                <div class="panel">
                    <div class="send"><p>配送费用:<span class="span">{{complete.distributionFee}}元</span></p></div>
                    <div class="send"><p>完成时间:<span class="span">{{complete.finishTime.substring(0,10)}}</span></p></div>
                    <div class="send" style="margin-bottom: 0px"><p>处理结果:<span class="span">{{complete.dealResult}}</span></p></div>
                </div>
             </div>
                 <div class="liA" style="margin-bottom: 0px" v-if="imgList.length!=0"><span>图片</span></div>
                <div class="potential01"  v-if="imgList.length!=0">
<!--                    图片-->
                    <div class="panel01 withMargin">
<!--                        <div class="swiper_show">-->
                            <div class="content">
                                    <van-swipe :loop="false" :width="115"  :show-indicators="false"  :touchable="touchPlay" :autoplay="autoPlay">
                                        <van-swipe-item  v-for="(url,i) in imgList" @click="previewImg(i)" :key="i">
                                            <div class="imgWrap">
                                                <img  :src="url" class="imgSrc"/>
                                            </div>
                                        </van-swipe-item>
                                        <div class="num">
                                            {{imgList.length}} 图
                                        </div>
                                    </van-swipe>
                            </div>
<!--                        </div>-->
                    </div>
                </div>

                <div v-if="afterRentStatus==11">
                    <div class="li" style="margin-top: 5px"><span>验收记录</span></div>
                    <div v-for="(item,index) in afterrentHisotryList" :key="index" style="width: 100%;overflow: hidden" >
                        <div class="panel" style="border-top-right-radius: 0px;border-top-left-radius: 0px;margin-top: 2px" >
                            <div class="panelHeader">
                                <div class="panelInfo one"><label>操作人：<span style="color: #878787">{{item.changeStaffName}}</span></label></div>
                                <div class="panelInfo" style="margin-bottom: 5px"><label>验收时间：<span style="color: #878787">{{item.changeTime.substring(0,10)}}</span></label>
                                </div>
                            </div>
                            <div>
                                <!--                            <div class="panelInfo one" style="margin-bottom: 5px"><label>订单来源：<span style="color: #878787">{{item.costBear}}</span></label></div>-->
                                <div class="panelInfo" style="margin-bottom: 5px"><label>验收结果：<span style="color: #EE7E67">{{item.dealResult}}</span></label>
                                </div>
                            </div>
                            <div class="info float" style="font-size: 0.37333rem;margin-bottom: 0px"><p>最终费用：<span style="color: #EE7E67">{{item.fee}}元</span></p></div>
<!--                            <div class="info last" style="font-size: 0.37333rem;"><p>服务人员评价：<span style="color: #878787;line-height: 25px">{{item.evaluateStar}}</span></p></div>-->
                            <div class="normalBlock"  >
                                <div class="panelInfo" style="margin-bottom: 5px"><label>服务人员评价：</label></div>
<!--                                <div class="info float" style="font-size: 0.37333rem;margin-bottom: 0px;font-weight: 700" >服务人员评价</div>-->
                                <div class="blockTitle"><van-rate v-model="value" :count="5"  readonly  /></div>
                            </div>
                            <div class="info last" style="font-size: 0.37333rem;"><p>备注：<span style="color: #878787;line-height: 25px">{{item.remarks}}</span></p></div>
                        </div>
                    </div>
                </div>
            </van-tab>

            <van-tab title="物品清单">
                <div style="margin-top:30px">
                    <div class="review" v-for="(items,index) in tableList" :key="index" style="margin-top: 0px">
                        <div class="panel" style="border-top-right-radius: 0px;border-top-left-radius: 0px;margin-top: 2px">
                            <div class="panelHeader">
                                <div class="panelInfo one"><label>位置：<span style="color: #878787">{{items.roomName}}</span></label></div>
                                <div class="panelInfo"><label>类型：<span style="color: #878787">{{items.goodsAllocationClassName}}</span></label>
                                </div>
                            </div>
                            <div>
                                <div class="panelInfo one"><label>物品：<span style="color: #878787">{{items.itemName}}</span></label></div>
                                <div class="panelInfo"><label>品牌：<span style="color: #878787">{{items.brand}}</span></label>
                                </div>
                            </div>
                            <div>
                                <div class="panelInfo one"><label>供应商：<span style="color: #878787">{{items.supplierName}}</span></label></div>
                                <div class="panelInfo"><label>金额：<span style="color: #878787">{{items.finalMoney?items.finalMoney:'0.00'}}元</span>
<!--                                    <span class="pictrue"></span>-->
                                </label>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>


    </div>
</template>
<script>
    import {
        NavBar,
        Tab,
        Tabs,
        Row,
        Col,
        Rate,
        Swipe,
        SwipeItem,
        ImagePreview,
        Image as VanImage,
    } from 'vant'
    import Vue from 'vue';
    Vue.use(Swipe);
    Vue.use(SwipeItem);
    import BScroll from "better-scroll";
    import Divider from "vant/lib/divider";
    import {deliveryCompletedHeadList,distributionDetailFinishInit,queryDistributionApprovalHistory} from "../../getData/getData";
    import {globaluserId,getStaffId, responseUtil} from "../../libs/rongxunUtil";
    export default {
        name: 'fitmentAudit',
        components: {
            [NavBar.name]: NavBar,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Row.name]: Row,
            [Col.name]: Col,
            [BScroll.name]: BScroll,
            [Divider.name]: Divider,
            [Rate.name]: Rate,
            [Swipe .name]:Swipe,
            [SwipeItem .name]:SwipeItem,
            [ImagePreview.Component.name]:ImagePreview.Component,
            [VanImage.name]: VanImage,
        },
        data() {
            return {
                active:0,
                start:'',
                showApply:false,
                aaa:true,


                evaluation:'5',
                num:'5',
                //接口数据
                did:'',
                value:1,
                applyList:[],
                tableList:[],
                applyMessage:[],
                imgList:[],
                afterrentHisotryList:[],//验收数据
                afterRentStatus:'',
                autoPlay:' ',//是否自动滑动
                touchPlay:false,//是否手动滑动
                //图片列表
                imagesList: [
                    'https://img.yzcdn.cn/vant/cat.jpeg',
                    'https://img.yzcdn.cn/vant/apple-1.jpg',
                    'https://img.yzcdn.cn/vant/apple-2.jpg',
                    'https://img.yzcdn.cn/vant/cat.jpeg',
                    'https://img.yzcdn.cn/vant/cat.jpeg',
                    'https://img.yzcdn.cn/vant/cat.jpeg',
                ],
                items: {
                    acceptor:'沉思',
                    result:'通过',
                    bears:'公司',
                    finalCost:'45.00',
                    time:'2020-12-12 12:30',
                    note:'你空间上看到好快的哈，好快的宽带哈，宽带好快'
                },
                apply:{
                    title:'中海紫金冠地—星海公园—中山路535—1—3号1单元501',
                    repairman:'沉思',
                    phoneNumber:'21222311221',
                    source:'公司',
                    degree:'紧急',
                    appointmentTime:'2020-02-12  12:05',
                    note:'这里坏了这里坏了这里坏了这里坏了这里坏了这里坏了'
                },
                Send:{
                    person:'唉关系',
                    total:'56453.00',
                    time:'2020-02-12 13:45',
                    describe:'大大浑善达克沙地哈哈哈哈哈哈哈哈',
                },
                complete:{
                    dealResult: '',
                    distributionFee: '',
                    finishTime: '',
                },
                list:{
                    location:'公区',
                    type:'家具',
                    items:'床',
                    brand:'席梦思',
                    supplier:'网销额',
                    money:'23445.00'
                },

                itemListing: [
                    {

                        id: '1221520',
                        type: '家具',
                        place: '公区',
                        items: '床',
                        brand: '席梦思',
                        supplier:'网销额',
                        sum:'23445.00'

                    },

                    {

                        id: '1221520',
                        type: '家具',
                        place: '公区',
                        items: '床',
                        brand: '席梦思',
                        supplier:'网销额',
                        sum:'23445.00'

                    },

                    {

                        id: '1221520',
                        type: '家具',
                        place: '公区',
                        items: '床',
                        brand: '席梦思',
                        supplier:'网销额',
                        sum:'23445.00'

                    }
                ],

            }
        },

        methods: {
            //配货申请详情初始化
            deliveryCompletedHeadList(){
                let that = this
                let initData = {
                    user_id :getStaffId(),
                    id:that.did
                }
                deliveryCompletedHeadList(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        console.log(response)
                        if (response.data.code==0){
                            that.applyList=response.data.data.headList;
                            that.tableList=response.data.data.tableList;
                            that.applyMessage=response.data.data.item
                        }else if (response.data.code!=0){
                            responseUtil.alertMsg(that,response.code)
                        } else {
                            responseUtil.alertMsg(that,response.code)
                        }
                    })
                })
            },

            queryDistributionApprovalHistory(){
                let that = this
                let initData = {
                    id:that.did
            }
                queryDistributionApprovalHistory(initData).then(function (response) {
                responseUtil.dealResponse(that,response,()=>{
                    console.log(response)
                    if (response.data.code==0){
                        that.afterrentHisotryList=response.data.data.afterrentHisotryList;
                        if (response.data.data.afterrentHisotryList.length!=0){
                            that.value=Number(response.data.data.afterrentHisotryList[0].evaluateStar)
                            // parseInt(that.value)

                        }

                    }else if (response.data.code!=0){
                        responseUtil.alertMsg(that,response.code)
                    } else {
                        responseUtil.alertMsg(that,response.code)
                    }
                })
            })
    },


            //配货申请详情初始化
            distributionDetailFinishInit(){
                let that = this
                let initData = {
                    id:that.did
                }
                distributionDetailFinishInit(initData).then(function (response) {
                responseUtil.dealResponse(that,response,()=>{
                    console.log(response)
                    if (response.data.code==0){
                        that.complete.dealResult=response.data.data.item.dealResult
                        that.complete.distributionFee=response.data.data.item.distributionFee
                        that.complete.finishTime=response.data.data.item.finishTime
                        // that.imgList=response.data.data.imgList
                        for (let i=0;i<response.data.data.imgList.length;i++){
                            that.imgList.push(response.data.data.imgList[i].path)
                        }
                        if (that.imgList.length>3){
                            that.touchPlay=true
                            that.autoPlay='3000'
                        }
                    }else if (response.data.code!=0){
                        responseUtil.alertMsg(that,response.code)
                    } else {
                        responseUtil.alertMsg(that,response.code)
                    }
                })
        })
    },
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },

            previewImg(start){
                ImagePreview(
                    {
                        images: this.imgList,
                        startPosition: start,
                        onClose() {
                            // do something
                        },
                    }
                )
            },

        },

        created() {
            this.did=this.$route.query.id
            this.afterRentStatus=this.$route.query.afterRentStatus
            this.deliveryCompletedHeadList();
            this.distributionDetailFinishInit();
            this.queryDistributionApprovalHistory();
            if (this.afterRentStatus==11) {
               //  this.$nextTick(() => {
               // if (this.$refs.wrapperBox!=undefined) {
               //     if (!this.scroll) {
               //         this.scroll = new BScroll(this.$refs.wrapperBox, {
               //             scrollX: true,
               //             eventPassthrough: 'vertical'
               //         })
               //     }
               // }
               //  })
            }

        }

    }
</script>
<style lang="less" scoped>
    @radius: 8px;
    @border: 1px solid rgb(244, 244, 244);
    //基础信息部分的css
    .li{
        //padding-top: 20px;margin-left: 18px;margin-bottom: 5px;color: #ff4400;
        padding-top: 15px;
        margin-bottom: 5px;
        margin-left: 18px;
        color: #FF5D3B;
        font-weight: 800;
    }
    .liA{
        /*padding-top: 15px;*/
        margin-bottom: 5px;
        margin-left: 18px;
        color: #FF5D3B;
        font-weight: 800;
    }
    .normalBlock{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
.blockTitle{
    margin-left: 40px;
}
    .apply {
        background-color: white;
        width: 345px;
        height: 70px;
        /*height: auto;*/
        font-size: 15px;
        font-weight: bold;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-left: 15px;
        margin-bottom: 0px;
    }
    .send{
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        margin-left: 15px;
        margin-bottom: -10px;
    }
    .span{
        font-weight: normal;
        color:  #878787;
        margin-left: 5px;
    }

    //图片墙部分的css
    .potential01 {
        width: 375px;
        background-color: #F8F8F8;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: hidden;
        color: #252525;
        margin-top: -15px;
        background-color: #F8F8F8;
        border-radius: 8px;
        .panel01 {
            width: 345px;
            padding: 10px 0px;
            background-color: rgb(255, 255, 255);
            border-radius: 8px;
            position: relative;
            font-size: 14px;
            text-align: left;
            overflow: hidden;
            &.withMargin {
                margin: 15px 0;
            }

        }
        .swiper_show {
            width: 100%;
            margin-left: 12px;
        }
    }
    .content {
        width: 100%;
     margin-left: 7px;
    }
    .num {
        position: absolute;
        left: 0px;
        top: 0px;
        font-size: 10px;
        background-color: rgb(0, 0, 0);
        opacity: .5;
        color: rgb(255, 255, 255);
        padding: 2px 6px;
        display: inline-block;
        border-radius: 10px;
        font-weight: bolder;
        line-height: 10px;
    }
    .imgWrap {
        width: 100px;
        height: 90px;
        border-radius: 8px;
        margin-right: 8px;
    }
    .imgSrc{
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }


    /*.applyDetailImgs{*/
        /*position: relative;*/
        /*height: 108px;*/
        /*width: 92%;*/
        /*margin: 0 auto 15px;*/
        /*background-color: #FFFFFF;*/
        /*border-radius: 8px;*/
        /*!*图片*!*/
        /*.applyDetailImg{*/
            /*width: 100px;*/
            /*height: 75px;*/
            /*border-radius: 8px;*/
            /*margin: 15px 5px 18px 5px ;*/
        /*}*/
        /*!*    轮播的显示图片数*!*/
        /*.custom-indicator{*/
            /*position: absolute;*/
            /*left: 15px;*/
            /*top: 20px;*/
            /*padding: 1px 7px;*/
            /*font-size: 12px;*/
            /*background: rgba(0, 0, 0, 0.1);*/
            /*border-radius: 10px;*/
            /*color: white;*/
        /*}*/
    /*}*/





    .font{
        margin-left: 13px;
        font-size: 15px;
        font-weight: bold;
    }

    .potential {
        width: 100%;
        background-color: rgb(250, 250, 250);
        font-size: 12px;
        box-sizing: border-box;
        overflow: hidden;
        .panel {//参数
            width: 345px;
            margin: 15px auto 15px;
            background-color: rgb(255, 255, 255);
            border-radius: @radius;
            position: relative;
            font-size: 15px;
            text-align: left;
            overflow: hidden;

            .info {//参数
                &.first {
                    padding: 10px 8px 0px 14px;

                    p {
                        margin: 6px 0;
                        font-weight: bolder;

                        span {
                            font-weight: normal;
                            color: rgba(153, 153, 153, 1);
                        }
                    }
                }

                &.sec {
                    padding: 0px 14px 0px 14px;

                    p {
                        margin: 6px 0;
                        font-weight: bolder;

                        span {
                            font-weight: normal;
                            color: rgb(244, 99, 76);
                        }
                    }
                }

                &.other {
                    padding: 0px 14px 0px 14px;

                    p {
                        margin: 6px 0;
                        font-weight: bolder;

                        span {
                            font-weight: normal;
                            color: rgba(153, 153, 153, 1);
                        }
                    }
                }

                &.float {
                    padding: 0px 8px 0px 14px;

                    p {
                        margin: 0 0 6px 0;
                        font-weight: bolder;

                        span {
                            font-weight: normal;
                            color: rgba(153, 153, 153, 1);
                        }
                    }
                }

                &.last {
                    padding: 0px 8px 20px 14px;

                    p {
                        margin: 0 0 6px 0;
                        font-weight: bolder;

                        span {
                            font-weight: normal;
                            color: rgba(153, 153, 153, 1);
                        }
                    }
                }

                &.footer {
                    padding: 6px 8px 12px 14px;

                    span {
                        font-weight: normal;

                    }

                }
            }

            .panelHeader {//参数
                padding-top: 14px;
            }

            .panelFooter {
                padding-bottom: 14px;
            }

            .panelInfo {//参数
                padding: 0 6px 2px 14px;
                font-size: 14px;

                &.one {
                    width: 42.5%;
                    float: left;
                }
                &.two {
                    width: 38%;
                    float: left;
                }

                label {
                    font-weight: bolder;

                    .fitment {
                        color: #f00;
                    }

                    span {
                        font-weight: normal;
                        color: rgba(153, 153, 153, 1);
                    }
                }

            }

        }

    }


    //循环div部分的css
    .review{//参数
        margin:  1px 15px 15px;
        height: auto;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
    }

    .panelInfo{//参数

        padding: 5px 14px;
        font-size: 14px;
        p{
            font-weight: bolder;
            margin: 15px 0px;
            .fitment{
                color: #f00;
            }
            span{
                font-weight: normal;
                color: rgba(153, 153, 153, 1);
            }
        }
    }
    .pictrue{
        display: inline-block;
        background: url("../../assets/images/Editing.png");
        width: 16px;
        height: 16px;
        background-size: 16px;
        margin-left: 20px;
        margin-bottom: -2px;
    }

</style>
